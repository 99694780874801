@mixin prefix($property, $value, $prefixes) {
    @each $prefix in $prefixes {
        -#{$prefix}-#{$property}: $value;
    }
    #{$property}: $value;
}


@mixin prefixBrowsers($property, $value, $prefixes) {
    @include prefix($property, $value, moz webkit ms o);
}



@mixin loopClasses ($prop, $classMap) {
    @each $name, $value in $classMap {
        &-#{$name} {
            #{$prop}: $value;
        }
    }
}

@mixin xyClasses($prop, $classMap){
    @each $name, $value in $classMap {
        &x-#{$name} {
            #{$prop}-left: $value;
            #{$prop}-right: $value;
        }
    }
    @each $name, $value in $classMap {
        &y-#{$name} {
            #{$prop}-top: $value;
            #{$prop}-bottom: $value;
        }
    }
}

$directions: (
    t:top,
    r:right,
    b:bottom,
    l:left
);

@mixin directionLoopClasses($prop, $classMap){
    @each $direction, $directionValue in $directions {
        &#{$direction} {
            @include loopClasses(#{$prop}-#{$directionValue},$classMap);
        }
    }
    @include loopClasses(#{$prop},$classMap);
    @include xyClasses(#{$prop},$classMap);
}
