// Colors
$white: #fff;
$whiteLight: #F9F9FC;
$grey: #f6f6f6;
$greyLinearStart: #f8f8f8;
$greyLinearEnd: #f2f2f2;
$greyBorder: #e8e8e8;
$greyBorderLight: #e2e2e2;
$greyText: #8e8e8e;
$greyTextOnHover: lighten($greyText, 10);

$themeColor: #004261;
$footer: #004261;
$red: #c80003;
// Sizes
$size-xs: 320px;
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;

$size:(
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

$px18: 18px;



$min-h: (
	120px:120px,
    150px: 150px,
    350px: 350px
);

$pl: (
    5px:5px,
    15px:15px,
    40px:40px
);

$m: (
    15px:15px
);

$widthInPx: (
    50px:50px
);

$widthInPercentage: (
    50:50%
);

$fontSizes: (
    18px: 18px,
    22px: 22px
);
