@import "variables";


/* Backgrounds */
.bg-white {
    background-color: $white;
}

.bg-grey {
    background: $grey -webkit-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey -o-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey -moz-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey linear-gradient($greyLinearStart, $greyLinearEnd);
}

.bg-red {
    background-color: $red;
}
.txt-white {
    color: $white;
}
.txt-grey {
    color: $greyText;
}
.txt-theme {
    color: $themeColor;
}
/* Text Colors */
