@import "variables";
@import "components/topBar";
@import "components/mainMenu";
@import "components/mainFooter";
@import "components/propertyBox";

.btn-common {
    width: 150px;
}
.contact-block {
    padding: 20px;
    color: $greyText;
}
.btn-theme {
    @include button-variant($themeColor, $themeColor);
}
.btn-outline-theme {
    @include button-outline-variant($themeColor);
}

.main-page-search {
    label {
        display: block;
        color: $white;
    }
    position: relative;
    margin-top: -103px;
    background-color: $themeColor;
}
.home-arrow {
    position: absolute;
    opacity: 0.8;
    top: 35px;
    right: -55px;
}

.divider {
    background-color:#d7d7d7;
    max-width:100%;
    height:2px;
}
.underline-header-divider {
    margin: -5px 0 30px 0;
}

.underline-header {
    border-bottom: 8px solid $themeColor;
    padding-bottom: 3px;
    margin-bottom: 0;
    display: inline-block;
}

.page-header {
    width: 100%;
    height: 200px;
    display: block;
    position: relative;
    &::after {
        content: "";
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        background-size: cover !important;
    }
    &.page-about-us::after{
        background: url('/resources/img/headers/about_us.jpg') no-repeat;
    }
    &.page-properties::after{
        background: url('/resources/img/headers/properties.jpg') no-repeat;
    }
    &.page-contact::after{
        background: url('/resources/img/headers/contact.jpg') no-repeat;
    }
    &.page-drama::after{
        background: url('/resources/img/headers/drama.jpg') no-repeat;
    }
}

h1.page-header-title {
    position: absolute;
    top: 175px;
    padding: 10px;
    background-color: $themeColor;
    color: $white;
}
.remove-border {
    border: 0;
}