@import "../variables";

.top-bar {
    padding: 10px 0;
    font-size: 14px;
    border-bottom: 1px solid $greyBorder;
    background: $grey -webkit-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey -o-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey -moz-linear-gradient($greyLinearStart, $greyLinearEnd);
    background: $grey linear-gradient($greyLinearStart, $greyLinearEnd);

    ul {
        margin-bottom: 0;
    }
}
