@import "../variables";
.main-footer {
    background-color: $footer;
    padding: 25px;
    color: $greyText;
    h4 {
        color: $white;
        margin-bottom: 20px;
    }
    li {
        margin-bottom: 15px;
    }
}
