@import "variables";
@import "mixins";

.max-size {
    @include loopClasses(max-width, $size);
}
.min-h {
    @include loopClasses(min-height, $min-h);
}

.m {
    @include directionLoopClasses(margin, $m);
}

.p {
    @include directionLoopClasses(padding, $pl);
}

.width {
    @include loopClasses(width, $widthInPx);
}
.w {
    @include loopClasses(width, $widthInPercentage);
}

.font-size {
    @include loopClasses(width, $fontSizes);
}
.form-sm {
    max-width: $size-xs;
}
