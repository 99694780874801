.property-box {
    .card-title {
        margin-bottom: 0.3rem;
    }
}

.property-for {
    padding: 0.2rem;
    font-weight: bold;
    text-align: center;
}
