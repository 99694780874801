@import "../variables";

.main-menu {
    color: $greyText;
    box-shadow: 0px 0px 3px #9c9c9c;
    padding: 0;
    line-height: 40px;
    &.navbar-light .navbar-nav .nav-link {
        &:hover,
        &:focus {
            color: $themeColor;
        }
    }

    &.navbar-light .navbar-nav .nav-item {
        &:active,
        &.active {
            border-bottom: 3px solid $themeColor;
        }
    }

    &.navbar-light .navbar-nav .show > .nav-link,
    &.navbar-light .navbar-nav .active > .nav-link,
    &.navbar-light .navbar-nav .nav-link.show,
    &.navbar-light .navbar-nav .nav-link.active {
        color: $themeColor;
        &:hover,
        &:focus {
            color: $themeColor;
        }
    }
}
