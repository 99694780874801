@import "variables";
html,
body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

h1,
.h1 {
    color: $themeColor;
    font-size: 2rem;
}

h2,
.h2 {
    color: $themeColor;
    font-size: 1.8rem;
}

h3,
.h3 {
    color: $themeColor;
    font-size: 1.4rem;
}

h4,
.h4 {
    color: $themeColor;
    font-size: 1.2rem;
}

a {
    //color: $orange;
}

a:hover,
a:focus,
a:active,
a.active {
    //color: $orange;
}

.grey-link {
    color: $greyText;
    &:hover,
    &:focus,
    &:active,
    &.active {
        text-decoration: none;
        color: $greyTextOnHover;
    }
}

.mt-50px,
.my-50px {
    margin-top: 50px;
}
.mb-50px,
.my-50px {
    margin-bottom: 50px;
}

.text-404 {
    position: relative;
    top: -70px;
    font-size: 16px;
}